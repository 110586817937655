import React, { Fragment, useEffect, useState } from 'react';
import {
  ReviewAccountHolderName,
  ReviewIDDocument,
  ReviewPEP,
  ReviewRepresentative,
  ReviewUserName,
} from './ReviewCards';
import { AllDone, TabContentWrapper } from '../SidePanel.elements';
import { UserTypes } from '../SidePanel';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';

interface ReviewsTabProps {
  data: UserTypes;
}

const ReviewsTab: React.FC<ReviewsTabProps> = ({ data }) => {
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(`${data.getUser.user?.given_name} ${data.getUser.user?.family_name}`);
  }, [data]);
  const {
    getUser: {
      kycState: { naturalPerson, bankAccounts, status },
    },
    getVerificationDocuments,
    getIDINProvidedData,
  } = data;
  return (
    <TabContentWrapper>
      <ReviewUserName
        userId={status.userId}
        review={naturalPerson.reviews.userName}
        name={name}
        IDINUserInfo={getIDINProvidedData}
      />
      <ReviewIDDocument
        idName={naturalPerson.answers.idName.hasValue && naturalPerson.answers.idName.value}
        idBirthDate={
          naturalPerson.answers.idBirthDate.hasValue && naturalPerson.answers.idBirthDate.value
        }
        userId={status.userId}
        review={naturalPerson.reviews.idDocument}
        verificationDocuments={getVerificationDocuments}
        name={name}
      />
      <ReviewPEP
        idName={naturalPerson.answers.idName.hasValue && naturalPerson.answers.idName.value}
        idBirthDate={
          naturalPerson.answers.idBirthDate.hasValue && naturalPerson.answers.idBirthDate.value
        }
        IDINUserInfo={getIDINProvidedData}
        rejectReason={naturalPerson.rejectReason}
        trapetsResult={naturalPerson.answers.trapetsResult.hasValue ? JSON.parse(naturalPerson.answers.trapetsResult.value as unknown as string) : null}
        userId={status.userId}
        review={naturalPerson.reviews.pepHit}
        name={name}
      />
      {bankAccounts.map(({ reviews, answers, bankAccountId, bankAccountHolderNames }) => {
        return (
          <Fragment key={bankAccountId}>
            <ReviewAccountHolderName
              userId={status.userId}
              review={reviews.bankAccountHolderNames}
              bankAccountId={bankAccountId}
              isBusinessAccount={
                answers.isBusinessAccount.hasValue && answers.isBusinessAccount.value
              }
              IDINUserInfo={getIDINProvidedData}
              name={name}
              bankAccountHolderNames={bankAccountHolderNames}
            />
            <ReviewRepresentative
              userId={status.userId}
              review={reviews.isRepresentative}
              verificationDocuments={getVerificationDocuments}
              isBusinessAccount={
                answers.isBusinessAccount.hasValue && answers.isBusinessAccount.value
              }
              IDINUserInfo={getIDINProvidedData}
              bankAccountId={bankAccountId}
              name={name}
            />
          </Fragment>
        );
      })}
      <AllDone><CheckCircleOutlineIcon primaryColor="#1890FF" label="check" size="xlarge" /><br/>All Done!</AllDone>
    </TabContentWrapper>
  );
};

export default ReviewsTab;
