import React, { useState } from 'react';
import { TextCenter } from '../../styled';
import {
  ButtonDanger,
  InfoBlock,
  InfoBlockTitle,
  Item,
  ItemName,
  ItemValue,
  TabContentWrapper,
} from '../SidePanel.elements';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { User } from '../../../../domain/user';
import { KYCState } from '../../../../data/getUser';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { IDINUserInfo } from '../../../../domain/signicat';
import moment from 'moment';
import Button from '@atlaskit/button';
import { VerificationDocument } from '../../../../domain/verificationDocument';
import { useVerificationDocuments } from '../ReviewsTab/ReviewCards/hooks';
import { AttachedFileViewer } from '../ReviewsTab/ReviewCards';

interface ProfileTabProps {
  userId: string;
  user?: User;
  kycState: KYCState;
  IDINUserInfo: IDINUserInfo | null;
  documents: VerificationDocument[];
}

const ProfileTab: React.FC<ProfileTabProps> = ({ userId, user, kycState, IDINUserInfo, documents }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { documentBlobs, documentUrls } = useVerificationDocuments({
    verificationDocuments: documents,
    documentType: 'ID',
  });

  const SubscriptionPlanNames = {
    none: 'none',
    /** Original Flow+ subscription (both consumer and business) */
    FP1: 'Flow+', // FlowPlus
    /** Original Flow+ is split between consumer and business uses */
    FPC1: 'Flow+ Consumer', // FlowPlusConsumer
    FPCP1: 'Flow+ Pro Consumer', // FlowPlusConsumerPro
    FPB1: 'Flow+ Business', // FlowPlusBusiness
    /** Partner-specific subscription plan for business */
    FPBPF1: 'Flow+ Business (powered by Profit First)', // FlowPlusBusinessProfitFirst
    FPBA1: 'Flow+ Advanced', // FlowPlusBusinessAdvanced
  }

  type PlanKeys = keyof typeof SubscriptionPlanNames;

  const subscriptionFields = [
    'Status', 'Payment schedule', 'Subscribed until', 'Paid until', 'Plan'
  ]

  return (
    <TabContentWrapper>
      <InfoBlock>
        <InfoBlockTitle>Personal</InfoBlockTitle>
        <Item>
          <ItemName>Name (Registration)</ItemName>
          <ItemValue>
            {user?.given_name} {user?.family_name}
          </ItemValue>
        </Item>
        <Item>
          <ItemName>Email {IDINUserInfo?.email ? '(iDIN)' : ''}</ItemName>
          <ItemValue>{user?.email || IDINUserInfo?.email || '-'}</ItemValue>
        </Item>
        <Item>
          <ItemName>Email verified</ItemName>
          <ItemValue>
            {user?.email_verified ? (
              <CheckCircleOutlineIcon primaryColor="#2FB52C" label="check" size='small' />
            ) : (
              <CrossCircleIcon primaryColor="#ff0000" label="cross" size='small' />
            )}
          </ItemValue>
        </Item>
        <Item>
          <ItemName>ID</ItemName>
          <ItemValue>{userId}</ItemValue>
        </Item>
      </InfoBlock>
      <InfoBlock>
        <InfoBlockTitle>KYC</InfoBlockTitle>
        <Item>
          <ItemName>Given name (iDin)</ItemName>
          <ItemValue>{IDINUserInfo?.given_name || '-'}</ItemValue>
        </Item>
        <Item>
          <ItemName>Family name (iDin)</ItemName>
          <ItemValue>{IDINUserInfo?.family_name || '-'}</ItemValue>
        </Item>
        <Item>
          <ItemName>Date of Birth (iDin)</ItemName>
          <ItemValue>{IDINUserInfo?.birthdate || '-'}</ItemValue>
        </Item>
        <Item>
          <ItemName>Location (iDin)</ItemName>
          <ItemValue>{IDINUserInfo?.address?.country || '-'}</ItemValue>
        </Item>
        <Item>
          <ItemName>Verification status</ItemName>
          <ItemValue>
            {kycState.naturalPerson.verificationStatus === 'VERIFIED' ? (
              <CheckCircleOutlineIcon primaryColor="#2FB52C" label="check" size='small' />
            ) : (
              <CrossCircleIcon primaryColor="#ff0000" label="cross" size='small' />
            )}
            {kycState.naturalPerson.verificationStatus || '-'}
          </ItemValue>
        </Item>
        {kycState.naturalPerson.rejectReason && (
          <Item>
            <ItemName>Rejection reason</ItemName>
            <ItemValue>{kycState.naturalPerson.rejectReason}</ItemValue>
          </Item>
        )}
        <Item>
          <ItemName>Risk qualification</ItemName>
          <ItemValue capitalize={true}>{kycState.status.riskQualification || '-'}</ItemValue>
        </Item>
      </InfoBlock>
      <InfoBlock>
        <InfoBlockTitle>Flow account</InfoBlockTitle>
        <Item>
          <ItemName>Subscription type</ItemName>
          { user?.subscription ? 
          (
            <>
              {
                user.subscription.split("|").map((sub, idx) => { 
                  const plan: PlanKeys | null = idx === 4 ? sub as PlanKeys : null;
                  return (
                    <ItemValue key={sub}><strong>{subscriptionFields[idx]}</strong>: {idx === 4  && plan ? SubscriptionPlanNames[plan] : sub}</ItemValue>
                  )
                })
              }
            </>
          ) : 
          user ?
          ( <ItemValue>Flow Free</ItemValue> )
          :
          ( <ItemValue>None: deleted account</ItemValue> )
          }
        </Item>
        <Item>
          <ItemName>Registered on</ItemName>
          <ItemValue>{user ? moment(user?.createDate).format('YYYY-MM-DD hh:mm') : '-'}</ItemValue>
        </Item>
      </InfoBlock>
      { documentUrls.length > 0 ? (
      <InfoBlock>
        <InfoBlockTitle>Documents</InfoBlockTitle>
        {documentUrls.map((doc, idx) => (
          <Item>
            <ItemName>{doc}</ItemName>
            <ItemValue><AttachedFileViewer key={doc} mimeType={documentBlobs[idx].type} url={doc} /></ItemValue>
          </Item>
        ))}
      </InfoBlock> ) : null }
      {user && (
        <TextCenter style={{ paddingTop: '20px' }}>
          <ButtonDanger onClick={showModal}>Delete user account</ButtonDanger>
        </TextCenter>
      )}


      <ModalTransition>
        {user && isModalVisible && (
          <Modal onClose={handleCancel}>
            <ModalHeader>
              <ModalTitle>Delete User</ModalTitle>
            </ModalHeader>
            <ModalBody>
              Do you want to delete {user.given_name} {user.family_name} ?
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={handleCancel}>
                Cancel
              </Button>
              <Button appearance="danger" onClick={handleOk} autoFocus>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </TabContentWrapper>
  );
};

export default ProfileTab;
